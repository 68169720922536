<template>
  <a-modal
    width="900px"
    v-model="isShow"
    title="已支付详情"
    :mask-closable="false"
    :body-style="{ paddingLeft: 20 }"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loading"
    />
  </a-modal>
</template>

<script>

import { findServiceOrderPaidList } from '@/api/order'

export default {
  name: 'Paid',
  props: {
    orderId: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      data: [],
      loading: true
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '支付单号',
          dataIndex: 'order_number',
          fixed: 'left'
        },
        {
          title: '支付时间',
          dataIndex: 'pay_at'
        },
        {
          title: '支付途径',
          dataIndex: 'pay_way'
        },
        {
          title: '实际支付金额(元)',
          dataIndex: 'payment_amount'
        }
      ]
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },
    fetchData() {
      this.loading = true
      findServiceOrderPaidList(this.orderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
